/* Leaflet popup override */
.leaflet-pane {
	z-index: 40 !important;
}

.leaflet-top,
.leaflet-bottom {
	z-index: 41 !important;
}

.leaflet-container {
	font-family: unset !important;
}

.leaflet-popup {
	text-align: unset !important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-content {
	display: block !important;
	margin: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	color: inherit !important;
}

.leaflet-popup-content {
	margin: 0 !important;
	font-size: 1rem !important;
	color: black !important;
}

.leaflet-popup-content a {
	/* all: unset !important; */
	color: unset !important;
	box-shadow: unset !important;
}

.leaflet-popup-content .font-bold {
	font-weight: bold !important;
}

.leaflet-popup-content p.mb-1,
.leaflet-popup-content .mb-1 {
	margin: 0 !important;
	margin-bottom: 0.25rem !important;
}

/* Popup arrow */
.leaflet-popup-tip {
	box-shadow: unset !important;
}

/* Controls */
.leaflet-control-zoom.leaflet-bar.leaflet-control {
	border-radius: 9999px !important;
	overflow: hidden;
	border: 1px solid rgb(255, 67, 56);
}

.leaflet-control-zoom.leaflet-bar.leaflet-control .leaflet-control-zoom-in,
.leaflet-control-zoom.leaflet-bar.leaflet-control .leaflet-control-zoom-out {
	font-size: 1rem !important;
	font-family: inherit !important;
	font-weight: 400 !important;
}
